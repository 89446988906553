import React, { useEffect, useState } from "react";
import fire from "./firebase/config";
import {Button} from 'react-bootstrap'
import { useHistory } from "react-router";
import heading from './assets/meetra_long.png'
import Footer from './Footer';

export default function UserNewTraining({ location }) {
  const history = useHistory();
  const [trainings, setTrainings] = useState([]);
  const [newTraining, setNewTraining] = useState();
  const email = location.state.email;
  var trainingProg = location.state.trainingProg;
  const db = fire.firestore();

  const handleSelect = (e) => {
    setNewTraining(e.target.value);
    console.log(e);
  };
  const handleClick = async () => {
    if (Array.isArray(trainingProg)) {
      trainingProg.push(newTraining);
      var userRef = db.collection("users").doc(email);
      var setWithMerge = await userRef.set(
        {
          trainingProg: trainingProg,
        },
        { merge: true }
      );
    } else {
      console.log("Inside else handle click");
      trainingProg = trainingProg.split(")"+1);
      trainingProg.push(newTraining);
      var userRef = db.collection("users").doc(email);
      var setWithMerge = await userRef.set(
        {
          trainingProg: trainingProg,
        },
        { merge: true }
      );
      console.log(trainingProg);
    }
  };
  const getTrainings = async () => {
    let dummyTrainings = [];
    if (Array.isArray(trainingProg)) {
      console.log("Inside if");
      await db
        .collection("trainings")
        .where("trainingName", "in", trainingProg)
        .get()
        .then((snapshot) => {
          snapshot.forEach((element) => {
            if (element.data().isEnabled) {
              dummyTrainings.push(element.data());
            }
          });
        });
      console.log({ dummyTrainings });
      setTrainings([...dummyTrainings]);
    } else {
      console.log("Inside else");
      await db
        .collection("trainings")
        .where("trainingName", "!=", trainingProg)
        .get()
        .then((snapshot) => {
          snapshot.forEach((element) => {
            if (element.data().isEnabled) {
              dummyTrainings.push(element.data());
            }
          });
        });
      console.log(dummyTrainings);
      setTrainings([...dummyTrainings]);
    }
  };

  useEffect(() => {
    console.log(email);
    console.log(trainingProg);
    getTrainings();
  }, []);

  return (
    <>
      <div className="main-container">
            <center><img src={heading} width="70%"></img></center>
            <hr/>
            <div className="head">
            <h1>Post-Training Questionnaire</h1>
            <Button className="btn1-signout" type="submit" onClick={() => history.push({pathname:'/Dashboard'})}>Home</Button></div>
            <hr/>
      <label>Select Training Program:</label>
      <select required onChange={handleSelect}>
        <option selected disabled>
          Program
        </option>
        {trainings.map((item) => {
          return (
            <option value={item.trainingName + ` (` + item.trainingDate + `)`}>
              ({item.trainingDate}) {item.trainingName}
            </option>
          );
        })}
      </select>
      <button onClick={handleClick}>Register</button>
      </div>
      <Footer/>
    </>
  );
}
