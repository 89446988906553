import React, { useEffect, useState } from "react";
import {Button} from 'react-bootstrap'
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import heading from './assets/meetra_long.png'
import Footer from './Footer';
import fire from "./firebase/config";
import 'firebase/firestore';
export default function TrainingDropdown(props) {
    const history = useHistory();
    const location = useLocation();
    const [train, setTrain] = useState([]);
    var trainingProg = location.state.trainingProg;
    var userName = location.state.email;
    const db = fire.firestore();
    const handleRegister = () => {
        trainingProg = train + '; ' + trainingProg;
        db.collection('users').doc(userName).set({
            trainingProg: trainingProg
        }, { merge: true });
        console.log(trainingProg)
    }
    return (

        <div className="main-container">
            <center><img src={heading} width="70%"></img></center>
            <hr/>
            <div className="head">
            <h1>New course registration</h1>
            <Button className="btn1-signout" type="submit" onClick={() => history.push({pathname:'/Dashboard'})}>Home</Button></div>
            <hr/>
            
            <center>  
                <label>User name: {userName}</label>                                  
                <br></br>
                <select required onChange={e => setTrain(e.target.value)}>
                    <option>Hydra1</option>
                    <option>Hydra2</option>
                    <option>Hydra3</option>
                </select>
                <Button variant="outlined" className="btn1" type="submit" onClick={handleRegister}>ADD</Button>
            </center>
            <Footer/>
        </div>
    )
}
