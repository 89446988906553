import React, { Component } from 'react';
import fire from './firebase/config'
import { Table } from '@material-ui/core';
import { forwardRef } from 'react';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { makeStyles } from "@material-ui/core";
import { Button } from "react-bootstrap";
import heading from "./assets/meetra_long.png";
import Footer from "./Footer";

class Attendance extends Component {

    constructor(props)
    {
        super(props)
        this.state={
            info:[],
        }
    }
   async componentDidMount()
    {
        let data=[];
        const db = fire.firestore();
    
     await db.collection('users').get().then(snapshot => {
         snapshot.forEach(element=>{
            // if(element.data().trainingProg === "Orientation on JJM with DPR preparation (29/07/2021)"){
             data.push(element.data())
          // }
         })
         console.log(data)
      })
      var ndata=JSON.stringify(data)
      console.log(ndata)
      this.setState({ info: data })
    
        console.log(this.state.info)
        // var data
        // const db = fire.firestore();
        // await db.collection("users").get().then((querySnapshot) => {
         
        //     // Loop through the data and store
        //     // it in array to display
        //      querySnapshot.forEach(element => {
        //           data = element.data();
        //           console.log(data)
        //            this.setState({
        //                info: (arr=>[...arr,data])
        //            }) 
        //     });
             
           
        // })
        // console.log(this.state.info)
        
    }
    render() {
        const arr=this.state.info
        const tableIcons = {
          Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
          Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
          DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
          Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
          Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
          Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
          FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
          LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
          NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
          PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
          ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
          SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
          ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
          ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        };
        return (
          <>
      <div className="main-container">
        <center>
          <img src={heading} width="70%"></img>
        </center>
        <hr />
        <div className="head">
          <h1>New Training Details</h1>
          <Button
            className="btn1-signout"
            type="submit"
            onClick={() => this.props.history.push({ pathname: "/Admin" })}
          >
            Dashboard
          </Button>
        </div>
        <hr />
            <div>
                <MaterialTable
          icons={tableIcons}
            columns={[
              { title: "Name", field: "name"},
              { title: "Phone", field: "phone"},
              { title: "Email", field: "email"},
              { title: "Office ", field: "oAddr"},
              { title: "State/UT", field: "stateUT"},
              { title: "District", field: "district"},
              { title: "Taluka", field: "taluka"},
              { title: "Pin", field: "pin"},
              { title: "Dept", field: "dept"},
              { title: "Designation", field: "designation"},
              { title: "Training Program", field: "trainingProg"},

            ]}
            data={arr}
            title="Attendance"
            options={{
              pageSize: 170,
                exportButton:true,
                filtering: true,
                sorting:true,
              headerStyle: {
                backgroundColor: '#3b3c3d',
                color: '#feffff',
                fontSize: '1rem'
              },
              tableLayout: {
                AddBox: true
              }
              
            }}
          /> 
            </div>
            </div>
            <Footer/>
            </>
        );
    }
}

export default Attendance;