import React from 'react'

export const Footer = () => {
    return(
        <div className="footer">
                <center>
                
                    &copy; {new Date().getFullYear()} Maharashtra Environmental Engineering Training and Research Academy (MEETRA) | Designed and Developed By: KASCOM - 9850090800
                
                </center>
        </div>
        )
}
export default Footer

