import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import heading from "./assets/meetra_long.png";
import Footer from "./Footer";
import fire from "./firebase/config";
import "firebase/firestore";
import {
  Select,
  Input,
  Typography,
  Switch,
  Card,
  notification,
  Button as ANTDButton,
} from "antd";
import ReactLoading from "react-loading";
import "antd/dist/antd.css";
import styled from "styled-components";
import { useSortBy } from "react-table";
const { Text } = Typography;

const Container = styled.div``;
const StyledSelect = styled(Select)`
  width: 100%;
  min-width: 20rem;
  margin-bottom: 3rem;
`;
const PageContainer = styled.div`
  position: relative;
  height: 100vh;
`;
const CustomText = styled(Text)`
  margin: 1rem auto;
`;
const CustomInput = styled(Input)`
  margin-bottom: 10px;
`;
const CustomSwitch = styled(Switch)`
  margin-left: 10px;
`;
const SwitchContainer = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  max-width: fit-content;
  align-items: center;
  text-align: start;
`;
const Loading = styled(ReactLoading)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const CustomCard = styled(Card)`
  width: fit-content;
  margin: 2rem auto;
  text-align: start;
`;

export default function ExistingTrainings({ location }) {
  const history = useHistory();
  const { Option } = Select;
  const [saveloading, setLoading] = useState();
  const [updated, setUpdated] = useState("");
  const [data, loadData] = useState(true);
  const [trainingList, setTrainingList] = useState([]);
  const [typevalue, setTypeValue] = useState();
  const [trainingObj, setTrainingObj] = useState({
    trainingName: "",
    trainingDate: "",
    meetLink: "",
    isCertificate: "",
    isFeedback: "",
    isPreQuestionnaire: "",
    isPostQuestionnaire: "",
    isMeetLink: "",
    isEnabled: "",
  });
  const db = fire.firestore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        loadData(true);
        db.collection("trainings").onSnapshot((response) => {
          const temp = [];
          response?.docs.map((res) => {
            console.log(res.data());
            const data = res.data();
            temp.push(data);
          });
          setTrainingList([...temp]);
          loadData(false);
        });
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const handleChange = (value) => {
    console.log(value);
    setTypeValue(value);
    console.log(typevalue);
    trainingList.map((training) => {
      if (training.trainingName === value) {
        console.log(training.trainingDate);

        setTrainingObj(training);
      }
    });
  };
  const handleSubmit = async (location) => {
    setLoading(true);
    console.log({ trainingObj });
    const dateChangeArray = trainingObj.trainingDate.split("/");
    const dateChangeString = dateChangeArray.join("");
    let res = await db
      .collection("trainings")
      .doc(trainingObj.trainingName+" ("+dateChangeString+")")
      .set({ ...trainingObj },{ merge: true });
    if (res !== null) {
      setLoading(false);
      setTimeout(() => {
        notification["success"]({
          message: "Values Updated",
        });
      }, 500);

      // window.location.reload();
    } else {
      console.log("Something went wrong");
    }
  };

  return (
    <PageContainer>
      {data ? (
        <Loading color="#00A1C9" type="spin" />
      ) : (
        <>
          <div className="main-container">
            <center>
              <img src={heading} width="70%"></img>
            </center>
            <hr />
            <div className="head">
              <h1>Existing Trainings</h1>
              <Button
                className="btn1-signout"
                type="submit"
                onClick={() => history.push({ pathname: "/Admin" })}
              >
                Dashboard
              </Button>
            </div>
            <hr />
            <CustomCard>
              <StyledSelect
                onChange={handleChange}
                defaultValue="Select the Training"
              >
                {trainingList.map((training) => (
                  <Option key={training.trainingName}>
                    {training.trainingName} ({training.trainingDate})
                  </Option>
                ))}
              </StyledSelect>
              <CustomText>Training Name </CustomText>
              <CustomInput disabled value={trainingObj.trainingName} />
              <CustomText>Training Date</CustomText>
              <CustomInput disabled value={trainingObj.trainingDate} />
              <CustomText>Meet Link </CustomText>
              <CustomInput disabled value={trainingObj.meetLink} />
              <SwitchContainer>
                <CustomText>Pre Questionnare</CustomText>
                <CustomSwitch
                  checked={trainingObj.isPreQuestionnaire}
                  onChange={() => {
                    setTrainingObj({
                      ...trainingObj,
                      isPreQuestionnaire: !trainingObj.isPreQuestionnaire,
                    });
                  }}
                />
              </SwitchContainer>
              <SwitchContainer>
                <CustomText>Meet Link</CustomText>
                <CustomSwitch
                  checked={trainingObj.isMeetLink}
                  onChange={() => {
                    setTrainingObj({
                      ...trainingObj,
                      isMeetLink: !trainingObj.isMeetLink,
                    });
                  }}
                />
              </SwitchContainer>
              <SwitchContainer>
                <CustomText>Post Questionnare</CustomText>
                <CustomSwitch
                  checked={trainingObj.isPostQuestionnaire}
                  onChange={() => {
                    setTrainingObj({
                      ...trainingObj,
                      isPostQuestionnaire: !trainingObj.isPostQuestionnaire,
                    });
                  }}
                />
              </SwitchContainer>
              <SwitchContainer>
                <CustomText>Feedback</CustomText>
                <CustomSwitch
                  checked={trainingObj.isFeedback}
                  onChange={() => {
                    setTrainingObj({
                      ...trainingObj,
                      isFeedback: !trainingObj.isFeedback,
                    });
                  }}
                />
              </SwitchContainer>
              <SwitchContainer>
                <CustomText>Certificate</CustomText>
                <CustomSwitch
                  checked={trainingObj.isCertificate}
                  onChange={() => {
                    setTrainingObj({
                      ...trainingObj,
                      isCertificate: !trainingObj.isCertificate,
                    });
                  }}
                />
              </SwitchContainer>
              <SwitchContainer>
                <CustomText>Enabled</CustomText>
                <CustomSwitch
                  checked={trainingObj.isEnabled}
                  onChange={() => {
                    setTrainingObj({
                      ...trainingObj,
                      isEnabled: !trainingObj.isEnabled,
                    });
                  }}
                />
              </SwitchContainer>
              <center>
                <Button
                  type="primary"
                  loading={saveloading}
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>{" "}
              </center>
            </CustomCard>
          </div>
          <Footer />
        </>
      )}
    </PageContainer>
  );
}
