import React from "react";
import { Card, Button } from "react-bootstrap";
import heading from "./assets/meetra_long.png";
import Footer from "./Footer";
import fire from "./firebase/config";
import "firebase/firestore";
import { useHistory } from "react-router";
import attendance from "./assets/attendance.jpg";
import toggle from "./assets/toggle.webp";
import new_training from "./assets/training_final.png"
export default function Admin() {
  const history = useHistory();
  const signOutUser = () => {
    fire
      .auth()
      .signOut()
      .then(function () {
        history.push({ pathname: "/Login" });
      })
      .catch(function (error) {});
  };
  return (
      <>
    <div className="main-container">
      <center>
        <img src={heading} width="70%"></img>
      </center>
      <hr />
      <div className="head">
        <h1>Admin Dashboard</h1>
        <Button className="btn1-signout" type="submit" onClick={signOutUser}>
          Sign out
        </Button>
      </div>
      <hr />
      <div className="grid">
        <Card style={{ width: "22rem" }} className="box">
          <center>
            <Card.Img variant="top" src={attendance} className="boximg" />
          </center>
          <Card.Body>
            <Card.Text>
              <center>Attendance</center>
            </Card.Text>
            <hr />
            <center><Button className="btn1" type="submit" onClick={() => history.push({ pathname: "/Attendance" })}>Check Attendance</Button></center>
          </Card.Body>
        </Card>
        <Card style={{ width: "22rem" }} className="box">
          <center>
            <Card.Img variant="top" src={new_training} className="boximg" />
          </center>
          <Card.Body>
            <Card.Text>
              <center>New Training</center>
            </Card.Text>
            <hr />
            <center><Button className="btn1" type="submit" onClick={() => history.push({ pathname: "/NewTraining" })}>Add New Training</Button></center>
          </Card.Body>
        </Card>
        <Card style={{ width: "22rem" }} className="box">
          <center>
            <Card.Img variant="top" src={toggle} className="boximg" />
          </center>
          <Card.Body>
            <Card.Text>
              <center>Existing Trainings</center>
            </Card.Text>
            <hr />
            <center><Button className="btn1" type="submit" onClick={() => history.push({ pathname: "/ExistingTrainings" })}>Edit Existing Training</Button></center>
          </Card.Body>
        </Card>
      </div>
    </div>
    <Footer />
    </>
  );
}
