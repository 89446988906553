import React, { useState } from "react";
import { Button } from "react-bootstrap";
import heading from "./assets/meetra_long.png";
import Footer from "./Footer";
import { useHistory } from "react-router";
import fire from "./firebase/config";
import "firebase/firestore";

export default function NewTraining() {
  const history = useHistory();
  const [trainings, setTrainings] = useState({
    trainingName: "",
    trainingDate: null,
    meetLink: "",
    preQuestionnaireLink: "",
    postQuestionnaireLink: "",
    isCertificate: false,
    isFeedback: false,
    isPreQuestionnaire: false,
    isPostQuestionnaire: false,
    isEnabled: false,
    isMeetLink: false
  });
  const handleChange = (e) => {
    setTrainings({ ...trainings, [e.target.name]: e.target.value });
  };
  const handleClick = () => {
    const dateChangeArray = trainings.trainingDate.split("/");
    const dateChangeString = dateChangeArray.join("");
    const db = fire.firestore();
    const trainingRef = db
      .collection("trainings")
      .doc(trainings.trainingName+" ("+dateChangeString+")")
      .set({ ...trainings });

    if (trainingRef != null) {
      history.push("/NewTraining");
    }
    console.log({ trainings });
  };
  return (
    <>
      <div className="main-container">
        <center>
          <img src={heading} width="70%"></img>
        </center>
        <hr />
        <div className="head">
          <h1>New Training Details</h1>
          <Button
            className="btn1-signout"
            type="submit"
            onClick={() => history.push({ pathname: "/Admin" })}
          >
            Dashboard
          </Button>
        </div>
        <hr />
        <div className="flexbox-container">
          <label>Training Name:</label>
          <input
            type="text"
            required
            name="trainingName"
            onChange={handleChange}
          ></input>
          <label>
            Training Date <small>(Eg.: 01-02/11/2021)</small>:
          </label>
          <input
            type="text"
            required
            name="trainingDate"
            onChange={handleChange}
          ></input>
          <label>Google Meet link:</label>
          <input
            type="text"
            required
            name="meetLink"
            onChange={handleChange}
          ></input>
          <label>Pre-Questionnaire link:</label>
          <input
            type="text"
            required
            name="preQuestionnaireLink"
            onChange={handleChange}
          ></input>
          <label>Post-Questionnaire link:</label>
          <input
            type="text"
            required
            name="postQuestionnaireLink"
            onChange={handleChange}
          ></input>
        </div>
        <Button
          variant="outlined"
          className="btn1"
          type="submit"
          onClick={handleClick}
        >
          Add Training
        </Button>
      </div>
      <Footer />
    </>
  );
}
