import React, { useEffect, useState } from "react";
import {Button} from 'react-bootstrap'
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import heading from './assets/meetra_long.png'
import Footer from './Footer';
import fire from "./firebase/config";
import 'firebase/firestore';
export default function PreTraining(props) {
    const history = useHistory();
    // const user = fire.auth().currentUser;
    // const [info , setInfo] = useState([]);
    // const db = fire.firestore();
    const location = useLocation();
    // useEffect(() => {
    //     Fetchdata();
    // },[]);
    // const Fetchdata = ()=>{
        
    //     db.collection("users").get().then((querySnapshot) => {
            
    //         // Loop through the data and store
    //         // it in array to display
    //         querySnapshot.forEach(element => {
    //             var data = element.data();
    //             setInfo(arr => [...arr , data]);
    //             // console.log(data.name);
    //         });
    //     })
    // }
    var trainingProg = location.state.trainingProg;
    let DIV;
    // info.map((data)=>{
    //         if(data.email === user.email){
    //             trainingProg = data.trainingProg;
    //         }
    //     }
    // )
    // if(trainingProg === "Orientation on JJM with DPR preparation (29/07/2021)"||"Orientation on JJM with DPR preparation (30/07/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    
    // if(trainingProg === "Hydraulic Modelling of Distribution System (02/08/2021)"||"Hydraulic Modelling of Distribution System (03/08/2021)"||"Hydraulic Modelling of Distribution System (04-05/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }

    // if(trainingProg === "Orientation on JJM with DPR preparation (06/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }

    // if(trainingProg === "Hydraulic Modelling of Distribution System (09/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }

    // if(trainingProg === "Hydraulic Modelling of Distribution System (11-12/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }

    // if(trainingProg === "Orientation on JJM with DPR preparation (13/08/2021) - Morning"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (13/08/2021) - Afternoon"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfajyfgW4FwhmIjIRR5sVtCdY9Uin-2bhhJeb88t8PTBZUi7g/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (17-18/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (20-21/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (23/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (24-25/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (26/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (27/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (30-31/08/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (01-02/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (03/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (04/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (06/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (07/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (08/09/2021) - Morning"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (13-14/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (15-16/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (17-18/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (20/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Orientation on JJM with DPR preparation (21/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXDMrVwpe317bkWNeMMCNhqAEUb25pneaV-qSr5QZF95wU9A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (22-23/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (24-25/09/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Water Quality Monitoring and Survellience (04-05/10/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd5WrFlvn3kDt5ncCK3yKmekCB0_dkBm1a6kOdnK6tXqDTlIw/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Hydraulic Modelling of Distribution System (11-12/10/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYU7aH4K9fnsk_iVr53_pEoOjlCX3NP9meH3TxgJ3JTJq5Bg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Rainwater Harvesting & Watershed Management (21-22/10/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSevq2zCvDZzujlqkhz0POPUEdcXbZSJ-riSuUu7BkQsom5iHA/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Grey Water Management (25-26/10/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfHQbrrXkm9crzHnSMxZ758zBLrM9im6J4aokSBf0c3UYdQ9g/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Operation and Maintenance of Individual Village Drinking Water Supply Scheme (28-29/10/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSclg7WuliwcU4dOvG-TppH12R17_oojgpDIB-yte_LwULjZ7g/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    // else if(trainingProg === "Water Quality Monitoring and Survellience (11-12/11/2021)"){
    //     DIV = <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd5WrFlvn3kDt5ncCK3yKmekCB0_dkBm1a6kOdnK6tXqDTlIw/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} />
    // }
    DIV = <div dangerouslySetInnerHTML={{ __html: trainingProg.preQuestionnaireLink}} />
    return (
        <>
        <div className="main-container">
            <center><img src={heading} width="70%"></img></center>
            <hr/>
            <div className="head">
            <h1>Pre-Training Questionnaire</h1>
            <Button className="btn1-signout" type="submit" onClick={() => history.push({pathname:'/Dashboard'})}>Home</Button></div>
            <hr/>
            <center>
                {DIV}
            </center>
        </div>
        <Footer/>
        </>
    )
}
