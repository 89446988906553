import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import post from "./assets/post.png";
import userLogo from "./assets/user.png";
import exam from "./assets/exam.jpg";
import feedback from "./assets/feedback.png";
import certificate from "./assets/certificate.png";
import heading from "./assets/meetra_long.png";
import "./GuideCard.css";
import fire from "./firebase/config";
import "firebase/firestore";
import "firebase/auth";
import { useHistory } from "react-router";
import certiSS from "./assets/MEETRA_CERTIFICATE_5.jpg";
import { jsPDF } from "jspdf";
import { Modal } from "semantic-ui-react";
import Footer from "./Footer";
import ReactLoading from "react-loading";
import styled from "styled-components";
import { Select } from "antd";

const Loading = styled(ReactLoading)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

function exampleReducer(state, action) {
  switch (action.type) {
    case "close":
      return { open: false };
    case "open":
      return { open: true, size: action.size };
    default:
      throw new Error("Unsupported action...");
  }
}

export default function Dashboard(props) {
  const history = useHistory();
  const signOutUser = () => {
    fire
      .auth()
      .signOut()
      .then(function () {
        localStorage.removeItem("user");
        history.push({ pathname: "/Login" });
      })
      .catch(function (error) {});
  };
  const [user, setUser] = useState("");
  // const user = fire.auth().currentUser;
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    size: undefined,
  });
  const [trainingList, setTrainingList] = useState([]);
  const [reverseTrainingList, setReverseTrainingList] = useState([]);
  const [trainingProg, setTrainingProg] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [training, setTraining] = useState({});
  const [activeTraining, setActiveTraining] = useState({});
  const { open, size } = state;
  const { Option } = Select;
  const db = fire.firestore();
  let data = [];
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Fetchdata();
  }, []);

  const Fetchdata = async () => {
    var email = "";
    const localEmail = localStorage.getItem("user");
    console.log("In fetch data");
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        email = user.email;
        console.log(email);
        setUser(email);
        if (email === null) {
          setLoading(true);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(true);
      }
    });

    //Fethcing logged in user info
    var tempUser;
    let dummyUser = {};
    let query;
    if (user) {
      query = db.collection("users").where("email", "==", user.email);
    } else {
      query = db.collection("users").where("email", "==", localEmail);
    }
    const userResult = await query.get();
    userResult.forEach((item) => {
      console.log(item.data());
      dummyUser = item.data();
      setUserInfo(item.data());
    });
    console.log("Er");
    console.log(dummyUser);
    var trainString = dummyUser.trainingProg;
    console.log(Array.isArray(trainString));
    console.log(trainString);
    var lastTraining = "";
    if (Array.isArray(trainString)) {
      lastTraining = trainString[trainString.length - 1];
      console.log("last", lastTraining);
      setTrainingProg([trainString]);
      setReverseTrainingList([...trainString.reverse()]);
    } else {
      setTrainingProg(trainString);
      lastTraining = trainString;
    }
    const wherequery = lastTraining
      ?.toString()
      .substring(0, lastTraining.indexOf(" ("));
    const dateQuery = lastTraining
      ?.toString()
      .substring(lastTraining.indexOf("(") + 1, lastTraining.indexOf(")"));
    console.log(wherequery);
    console.log(dateQuery);
    const trainingQuery = db
      .collection("trainings")
      .where("trainingName", "==", wherequery)
      .where("trainingDate", "==", dateQuery);
    const resultdata = await trainingQuery.get();
    resultdata.forEach((item) => {
      console.log(item.data());
      setActiveTraining(item.data());
    });
  };

  let PreTrainingButton,
    OnlineTrainingButton,
    PostTrainingButton,
    FeedbackButton,
    CertificateButton;
  PreTrainingButton = activeTraining.isPreQuestionnaire ? (
    <center>
      <Button
        className="btn1"
        variant="outlined"
        onClick={() =>
          history.push({
            pathname: "/PreTraining",
            state: { trainingProg: activeTraining },
          })
        }
        target="_blank"
        type="submit"
      >
        Attempt Pre-Questionnaire
      </Button>
    </center>
  ) : (
    <center>
      <Button
        disabled
        className="btn1-disabled"
        variant="outlined"
        onClick={() =>
          history.push({
            pathname: "/PreTraining",
            state: { trainingProg: activeTraining },
          })
        }
        target="_blank"
        type="submit"
      >
        Attempt Pre-Questionnaire
      </Button>
    </center>
  );
  OnlineTrainingButton = activeTraining.isMeetLink ? (
    <center>
      <a href={activeTraining.meetLink} target="_blank">
        <Button className="btn1" variant="outlined" type="submit">
          Join Google Meet
        </Button>
      </a>
    </center>
  ) : (
    <center>
      <a href={activeTraining.meetLink} target="_blank">
        <Button
          disabled
          className="btn1-disabled"
          variant="outlined"
          type="submit"
        >
          Join Google Meet
        </Button>
      </a>
    </center>
  );
  PostTrainingButton = activeTraining.isPostQuestionnaire ? (
    <center>
      <Button
        className="btn1"
        variant="outlined"
        onClick={() =>
          history.push({
            pathname: "/PostTraining",
            state: { trainingProg: activeTraining },
          })
        }
        type="submit"
      >
        Attempt Post-Questionnaire
      </Button>
    </center>
  ) : (
    <center>
      <Button
        disabled
        className="btn1-disabled"
        variant="outlined"
        onClick={() =>
          history.push({
            pathname: "/PostTraining",
            state: { trainingProg: activeTraining },
          })
        }
        type="submit"
      >
        Attempt Post-Questionnaire
      </Button>
    </center>
  );
  FeedbackButton = activeTraining.isFeedback ? (
    <center>
      <Button
        className="btn1"
        variant="outlined"
        onClick={() =>
          history.push({
            pathname: "/Feedback",
            state: { trainingProg: activeTraining },
          })
        }
        type="submit"
      >
        Provide Feedback
      </Button>
    </center>
  ) : (
    <center>
      <Button
        disabled
        className="btn1-disabled"
        variant="outlined"
        onClick={() =>
          history.push({
            pathname: "/Feedback",
            state: { trainingProg: activeTraining },
          })
        }
        type="submit"
      >
        Provide Feedback
      </Button>
    </center>
  );
  CertificateButton = activeTraining.isCertificate ? (
    <center>
      <Button
        className="btn1"
        variant="outlined"
        onClick={() => {
          // if (activeTraining.trainingName.length > 35) {
          //   var count=0;
          //   var index=0;
          //   for(var i=0;i<activeTraining.trainingName.length;i++) {
          //     console.log(activeTraining.trainingName.charAt(i));
          //     if(activeTraining.trainingName.charAt(i)==" "){
          //       count++;
          //     }
          //     if(count == 4){
          //       index = activeTraining.trainingName.indexOf(" ")
          //       break;
          //     }
          //   }
          //   var doc = new jsPDF("l", "pt");
          //   doc.addImage(certiSS, "png", 10, 10, 820, 570);
          //   doc.setFontSize(14);
          //   doc.setFont("arial", "normal");
          //   doc.text("This is to certify Mr./Ms.", 140, 346);
          //   doc.setFont("arial", "bold");
          //   doc.text(userInfo.name, 285, 346);
          //   doc.setFont("arial", "normal");
          //   doc.text("has successfully completed his/her training in", 140, 372);
          //   doc.setFont("arial", "bold");
          //   doc.text(activeTraining.trainingName.substring(0,index),400,372)
          //   doc.text(activeTraining.trainingName.substring(index),140,398)

          //       doc.text('Date: ',140,424)
          //       doc.setFont('arial','normal')
          //       doc.text(activeTraining.trainingDate,180,424)
          //       doc.setFont('arial','bold')
          //       doc.text('Dy. Director, MEETRA ',140,464)
          //       doc.text('Director, MEETRA ',550,464)
          //       doc.setFont('arial','normal')
          //       // doc.text('29/07/2021',180,450)
          //       doc.save('Certificate.pdf')
          //   dispatch({ type: "open", size: "mini" });
          // }
          // else {
          if (
            activeTraining.trainingName ===
            "Operation and Maintenance of Individual Village Drinking Water Supply Scheme"
          ) {
            var doc = new jsPDF("l", "pt");
            doc.addImage(certiSS, "png", 10, 10, 820, 570);
            doc.setFontSize(14);
            doc.setFont("arial", "normal");
            doc.text("This is to certify Mr./Ms.", 140, 346);
            doc.setFont("arial", "bold");
            doc.text(userInfo.name, 285, 346);
            doc.setFont("arial", "normal");
            doc.text(
              "has successfully completed his/her training in",
              140,
              372
            );
            doc.setFont("arial", "bold");
            doc.text('Operation and Maintenance of Individual Village',400,372)
            doc.text('Drinking Water Supply Scheme',140,398)
            doc.text('Date: ',140,424)
            doc.setFont('arial','normal')
            doc.text(activeTraining.trainingDate,180,424)
            doc.setFont('arial','bold')
            doc.text('Dy. Director, MEETRA ',140,464)
            doc.text('Director, MEETRA ',550,464)
            doc.setFont("arial", "normal");
            doc.save("Certificate.pdf");
            dispatch({ type: "open", size: "mini" });
          }
          else if (
            activeTraining.trainingName ===
            "Adv. Course on Geophysical Investigation & Exploration Techniques."
          ) {
            var doc = new jsPDF("l", "pt");
            doc.addImage(certiSS, "png", 10, 10, 820, 570);
            doc.setFontSize(14);
            doc.setFont("arial", "normal");
            doc.text("This is to certify Mr./Ms.", 140, 346);
            doc.setFont("arial", "bold");
            doc.text(userInfo.name, 285, 346);
            doc.setFont("arial", "normal");
            doc.text(
              "has successfully completed his/her training in",
              140,
              372
            );
            doc.setFont("arial", "bold");
            doc.text('Adv. Course on Geophysical Investigation',400,372)
            doc.text('& Exploration Techniques',140,398)
            doc.text('Date: ',140,424)
            doc.setFont('arial','normal')
            doc.text(activeTraining.trainingDate,180,424)
            doc.setFont('arial','bold')
            doc.text('Dy. Director, MEETRA ',140,464)
            doc.text('Director, MEETRA ',550,464)
            doc.setFont("arial", "normal");
            doc.save("Certificate.pdf");
            dispatch({ type: "open", size: "mini" });
          } 
          else {
            var doc = new jsPDF("l", "pt");
            doc.addImage(certiSS, "png", 10, 10, 820, 570);
            doc.setFontSize(14);
            doc.setFont("arial", "normal");
            doc.text("This is to certify Mr./Ms.", 140, 346);
            doc.setFont("arial", "bold");
            doc.text(userInfo.name, 285, 346);
            doc.setFont("arial", "normal");
            doc.text(
              "has successfully completed his/her training in",
              140,
              372
            );
            doc.setFont("arial", "bold");
            doc.text(activeTraining.trainingName, 400, 372);
            doc.text("Date: ", 140, 410);
            doc.setFont("arial", "normal");
            doc.text(activeTraining.trainingDate, 180, 410);
            doc.setFont("arial", "bold");
            doc.text("Dy. Director, MEETRA ", 140, 450);
            doc.text("Director, MEETRA ", 550, 450);
            doc.setFont("arial", "normal");
            doc.save("Certificate.pdf");
            dispatch({ type: "open", size: "mini" });
          }
          // }
        }}
        target="_blank"
        type="submit"
      >
        Get Certificate
      </Button>
    </center>
  ) : (
    (CertificateButton = (
      <center>
        <Button
          disabled
          className="btn1-disabled"
          variant="outlined"
          target="_blank"
          type="submit"
        >
          Get Certificate
        </Button>
      </center>
    ))
  );
  const handleSelect = async (e) => {
    console.log(e.target.value);
    const wherequery = e.target.value
      ?.toString()
      .substring(0, e.target.value.indexOf(" ("));
    console.log(wherequery);
    const trainingQuery = db
      .collection("trainings")
      .where("trainingName", "==", wherequery);
    const resultdata = await trainingQuery.get();
    resultdata.forEach((item) => {
      console.log(item.data());
      setActiveTraining(item.data());
    });
  };
  return (
    <div>
      {loading ? (
        <Loading color="#00A1C9" type="spin" />
      ) : (
        <div className="main-container">
          <center>
            <img src={heading} width="70%"></img>
          </center>
          <hr />
          <div className="head">
            <h1>Dashboard</h1>
            {/* <Button
              className="btn1-signout"
              type="submit"
              onClick={() =>
                history.push({
                  pathname: "/UserNewTraining",
                  state: {
                    email: userInfo.email,
                    trainingProg: userInfo.trainingProg,
                  },
                })
              }
            >
              New Training Registration
            </Button> */}

            <Button
              className="btn1-signout"
              type="submit"
              onClick={signOutUser}
            >
              Sign out
            </Button>
          </div>
          <hr />
          <p style={{ paddingLeft: "2rem", fontSize: "16px" }}>
            Welcome{" "}
            <i>
              <b>{userInfo.name}</b>
            </i>
          </p>
          <p style={{ paddingLeft: "2rem", fontSize: "16px" }}>
            You've registered for{" "}
            {Array.isArray(userInfo.trainingProg) ? (
              <select required onChange={handleSelect}>
                {reverseTrainingList.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            ) : (
              <i>{userInfo.trainingProg}</i>
            )}
          </p>
          <div className="grid">
            <Card style={{ width: "22rem" }} className="box">
              <center>
                <Card.Header style={{ fontSize: "1.3rem" }}>
                  <b>Step 1</b>
                </Card.Header>
              </center>
              <center>
                <Card.Img variant="top" src={exam} className="boximg" />
              </center>
              <Card.Body>
                <Card.Text>
                  <center>Pre-Training Questionnaire</center>
                </Card.Text>
                <hr />
                {PreTrainingButton}
              </Card.Body>
            </Card>
            <Card style={{ width: "22rem" }} className="box">
              <center>
                <Card.Header style={{ fontSize: "1.3rem" }}>
                  <b>Step 2</b>
                </Card.Header>
              </center>
              <center>
                <Card.Img variant="top" src={userLogo} className="boximg" />
              </center>
              <Card.Body>
                <Card.Text>
                  <center>Online Training</center>
                </Card.Text>
                <hr />
                {OnlineTrainingButton}
              </Card.Body>
            </Card>
            <Card style={{ width: "22rem" }} className="box">
              <center>
                <Card.Header style={{ fontSize: "1.3rem" }}>
                  <b>Step 3</b>
                </Card.Header>
              </center>
              <center>
                <Card.Img variant="top" src={post} className="boximg" />
              </center>
              <Card.Body>
                <Card.Text>
                  <center>Post-Training Questionnaire</center>
                </Card.Text>
                <hr />
                {PostTrainingButton}
              </Card.Body>
            </Card>
            <Card style={{ width: "22rem" }} className="box">
              <center>
                <Card.Header style={{ fontSize: "1.3rem" }}>
                  <b>Step 4</b>
                </Card.Header>
              </center>
              <center>
                <Card.Img variant="top" src={feedback} className="boximg" />
              </center>
              <Card.Body>
                <Card.Text>
                  <center>Feedback</center>
                </Card.Text>
                <hr />
                {FeedbackButton}
              </Card.Body>
            </Card>
            <Card style={{ width: "22rem" }} className="box">
              <center>
                <Card.Header style={{ fontSize: "1.3rem" }}>
                  <b>Step 5</b>
                </Card.Header>
              </center>
              <center>
                <Card.Img variant="top" src={certificate} className="boximg" />
              </center>
              <Card.Body>
                <Card.Text>
                  <center>Certificate</center>
                </Card.Text>
                <hr />
                {CertificateButton}
              </Card.Body>
            </Card>
            <Modal
              closeIcon
              size={size}
              open={open}
              onClose={() => dispatch({ type: "close" })}
            >
              <Modal.Header
                style={{
                  background:
                    "linear-gradient(to right, #0a1817, #4e5b62, #82a0ad)",
                  color: "#feffff",
                }}
              >
                e-Certificate Downloaded!
              </Modal.Header>
              <Modal.Content>
                <p>
                  Your e-Certificate is auto-downloaded. Please check your
                  downloads folder.
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  className="btn1"
                  onClick={() => dispatch({ type: "close" })}
                >
                  Okay
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
