import React from 'react';
import {Button} from '@material-ui/core'
import {Form} from 'react-bootstrap'
import './login.css'
import { Footer } from './Footer'
import { useHistory } from "react-router";
import logo from './assets/meetra_main_logo.png'
const Login = (props) => {
  const { email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    emailError,
    passwordError
  } = props;
  const history = useHistory();
  return (
    <div>
    <div className="main-container">
    <div className='login'>
      <div className='loginContainer'>
        <center><img src={logo} width='25%' height='25%'></img></center>
        <Form.Text className='header'>Trainee Login</Form.Text>
        <label>Enter Email:</label>
        <input type='text' autoFocus required value={email} onChange={e => setEmail(e.target.value)}/>
        <p className="errorMsg">{emailError}</p>
        <label>Enter Password:</label>
        <input type='password' required value={password} onChange={e => setPassword(e.target.value)}/>
        <p className="errorMsg">{passwordError}</p>
        <br></br>
        <Button variant="outlined" className="btn1" type="submit" onClick={handleLogin}>LOG IN</Button>
        <br/>
        <p>Not registered for Online Training? <Button style={{color: 'red'}} onClick={() => history.push({pathname:'/SignUp'})}>REGISTER</Button> </p>
        {/* <p>New training for existing user? <Button style={{color: 'red'}} onClick={() => history.push({pathname:'/TrainingDropdown'})}>REGISTER</Button> </p> */}
      </div>    
    </div>
    </div>
    <Footer/>
    </div>
  );
}

export default Login;<Footer/>