import React, { Component } from 'react';
import Select from 'react-select';

const options = [
    

    { value: 'Aheri',	label:'Aheri'},
    { value: 'Ahmedpur',	label:'Ahmedpur'},
    { value: 'Ajra',	label:'Ajra'},
    { value: 'Akkalkot',	label:'Akkalkot'},
    { value: 'Akkalkuwa',	label:'Akkalkuwa'},
    { value: 'Akola',	label:'Akola'},
    { value: 'Akole',	label:'Akole'},
    { value: 'Akot',	label:'Akot'},
    { value: 'Akrani (Dhadgaon)',	label:'Akrani (Dhadgaon)'},
    { value: 'Alibaug',	label:'Alibaug'},
    { value: 'Amalner',	label:'Amalner'},
    { value: 'Ambad',	label:'Ambad'},
    { value: 'Ambarnath',	label:'Ambarnath'},
    { value: 'Ambegaon (Ghodegaon)',	label:'Ambegaon (Ghodegaon)'},
    { value: 'Ambejogai',	label:'Ambejogai'},
    { value: 'Amgaon',	label:'Amgaon'},
    { value: 'Amravati',	label:'Amravati'},
    { value: 'Andheri',	label:'Andheri'},
    { value: 'Anjangaon-Surji',	label:'Anjangaon-Surji'},
    { value: 'Ardhapur',	label:'Ardhapur'},
    { value: 'Arjuni Morgaon',	label:'Arjuni Morgaon'},
    { value: 'Armori',	label:'Armori'},
    { value: 'Arni',	label:'Arni'},
    { value: 'Arvi',	label:'Arvi'},
    { value: 'Ashti',	label:'Ashti'},
    { value: 'Ashti',	label:'Ashti'},
    { value: 'Atpadi',	label:'Atpadi'},
    { value: 'Aundha Nagnath',	label:'Aundha Nagnath'},
    { value: 'Aurangabad',	label:'Aurangabad'},
    { value: 'Ausa',	label:'Ausa'},
    { value: 'Babhulgaon',	label:'Babhulgaon'},
    { value: 'Badnapur',	label:'Badnapur'},
    { value: 'Baglan (Satana)',	label:'Baglan (Satana)'},
    { value: 'Balapur',	label:'Balapur'},
    { value: 'Ballarpur',	label:'Ballarpur'},
    { value: 'Baramati',	label:'Baramati'},
    { value: 'Barshi',	label:'Barshi'},
    { value: 'Barshitakli',	label:'Barshitakli'},
    { value: 'Basmath',	label:'Basmath'},
    { value: 'Beed',	label:'Beed'},
    { value: 'Bhadgaon',	label:'Bhadgaon'},
    { value: 'Bhadravati',	label:'Bhadravati'},
    { value: 'Bhamragad',	label:'Bhamragad'},
    { value: 'Bhandara',	label:'Bhandara'},
    { value: 'Bhatukali',	label:'Bhatukali'},
    { value: 'Bhiwandi',	label:'Bhiwandi'},
    { value: 'Bhiwapur',	label:'Bhiwapur'},
    { value: 'Bhokar',	label:'Bhokar'},
    { value: 'Bhokardan',	label:'Bhokardan'},
    { value: 'Bhor',	label:'Bhor'},
    { value: 'Bhudargad',	label:'Bhudargad'},
    { value: 'Bhum',	label:'Bhum'},
    { value: 'Bhusawal',	label:'Bhusawal'},
    { value: 'Biloli',	label:'Biloli'},
    { value: 'Bodwad',	label:'Bodwad'},
    { value: 'Borivali',	label:'Borivali'},
    { value: 'Bramhapuri',	label:'Bramhapuri'},
    { value: 'Buldhana',	label:'Buldhana'},
    { value: 'Chakur',	label:'Chakur'},
    { value: 'Chalisgaon',	label:'Chalisgaon'},
    { value: 'Chamorshi',	label:'Chamorshi'},
    { value: 'Chandgad',	label:'Chandgad'},
    { value: 'Chandrapur',	label:'Chandrapur'},
    { value: 'Chandur',	label:'Chandur'},
    { value: 'Chandurbazar',	label:'Chandurbazar'},
    { value: 'Chandwad',	label:'Chandwad'},
    { value: 'Chikhaldara',	label:'Chikhaldara'},
    { value: 'Chikhli',	label:'Chikhli'},
    { value: 'Chimur',	label:'Chimur'},
    { value: 'Chiplun',	label:'Chiplun'},
    { value: 'Chopda',	label:'Chopda'},
    { value: 'Dahanu',	label:'Dahanu'},
    { value: 'Dapoli',	label:'Dapoli'},
    { value: 'Darwha',	label:'Darwha'},
    { value: 'Daryapur',	label:'Daryapur'},
    { value: 'Daund',	label:'Daund'},
    { value: 'Deglur',	label:'Deglur'},
    { value: 'Deola',	label:'Deola'},
    { value: 'Deoli',	label:'Deoli'},
    { value: 'Deoni',	label:'Deoni'},
    { value: 'Deori',	label:'Deori'},
    { value: 'Desaiganj (Vadasa)',	label:'Desaiganj (Vadasa)'},
    { value: 'Deulgaon Raja',	label:'Deulgaon Raja'},
    { value: 'Devgad',	label:'Devgad'},
    { value: 'Dhamangaon',	label:'Dhamangaon'},
    { value: 'Dhanora',	label:'Dhanora'},
    { value: 'Dharangaon',	label:'Dharangaon'},
    { value: 'Dharmabad',	label:'Dharmabad'},
    { value: 'Dharni (Amravati)',	label:'Dharni (Amravati)'},
    { value: 'Dharur',	label:'Dharur'},
    { value: 'Dhule',	label:'Dhule'},
    { value: 'Digras',	label:'Digras'},
    { value: 'Dindori',	label:'Dindori'},
    { value: 'Dodamarg (Kasal)',	label:'Dodamarg (Kasal)'},
    { value: 'Erandol',	label:'Erandol'},
    { value: 'Gadchiroli',	label:'Gadchiroli'},
    { value: 'Gadhinglaj',	label:'Gadhinglaj'},
    { value: 'Gaganbawada',	label:'Gaganbawada'},
    { value: 'Gangakhed',	label:'Gangakhed'},
    { value: 'Gangapur',	label:'Gangapur'},
    { value: 'Georai',	label:'Georai'},
    { value: 'Ghansawangi',	label:'Ghansawangi'},
    { value: 'Ghatanji',	label:'Ghatanji'},
    { value: 'Gondia',	label:'Gondia'},
    { value: 'Gondpimpri',	label:'Gondpimpri'},
    { value: 'Goregaon',	label:'Goregaon'},
    { value: 'Guhagar',	label:'Guhagar'},
    { value: 'Hadgaon',	label:'Hadgaon'},
    { value: 'Hatkanangale',	label:'Hatkanangale'},
    { value: 'Haveli',	label:'Haveli'},
    { value: 'Himayatnagar',	label:'Himayatnagar'},
    { value: 'Hinganghat',	label:'Hinganghat'},
    { value: 'Hingna',	label:'Hingna'},
    { value: 'Hingoli',	label:'Hingoli'},
    { value: 'Igatpuri',	label:'Igatpuri'},
    { value: 'Indapur',	label:'Indapur'},
    { value: 'Jafrabad',	label:'Jafrabad'},
    { value: 'Jalgaon',	label:'Jalgaon'},
    { value: 'Jalgaon Jamod',	label:'Jalgaon Jamod'},
    { value: 'Jalkot',	label:'Jalkot'},
    { value: 'Jalna',	label:'Jalna'},
    { value: 'Jamkhed',	label:'Jamkhed'},
    { value: 'Jamner',	label:'Jamner'},
    { value: 'Jaoli',	label:'Jaoli'},
    { value: 'Jat',	label:'Jat'},
    { value: 'Jawhar',	label:'Jawhar'},
    { value: 'Jintur',	label:'Jintur'},
    { value: 'Jivati',	label:'Jivati'},
    { value: 'Junnar',	label:'Junnar'},
    { value: 'Kadegaon',	label:'Kadegaon'},
    { value: 'Kagal',	label:'Kagal'},
    { value: 'Kaij',	label:'Kaij'},
    { value: 'Kalamb',	label:'Kalamb'},
    { value: 'Kalamb',	label:'Kalamb'},
    { value: 'Kalameshwar',	label:'Kalameshwar'},
    { value: 'Kalamnuri',	label:'Kalamnuri'},
    { value: 'Kalwan',	label:'Kalwan'},
    { value: 'Kalyan',	label:'Kalyan'},
    { value: 'Kamptee',	label:'Kamptee'},
    { value: 'Kandhar',	label:'Kandhar'},
    { value: 'Kankavli',	label:'Kankavli'},
    { value: 'Kannad',	label:'Kannad'},
    { value: 'Karad',	label:'Karad'},
    { value: 'Karanja',	label:'Karanja'},
    { value: 'Karanja',	label:'Karanja'},
    { value: 'Karjat',	label:'Karjat'},
    { value: 'Karjat (Matheran)',	label:'Karjat (Matheran)'},
    { value: 'Karmala',	label:'Karmala'},
    { value: 'Karvir',	label:'Karvir'},
    { value: 'Katol',	label:'Katol'},
    { value: 'Kavathe-Mahankal',	label:'Kavathe-Mahankal'},
    { value: 'Kelapur (Pandharkawada)',	label:'Kelapur (Pandharkawada)'},
    { value: 'Khalapur',	label:'Khalapur'},
    { value: 'Khamgaon',	label:'Khamgaon'},
    { value: 'Khanapur-Vita',	label:'Khanapur-Vita'},
    { value: 'Khandala',	label:'Khandala'},
    { value: 'Khatav (Vaduj)',	label:'Khatav (Vaduj)'},
    { value: 'Khed',	label:'Khed'},
    { value: 'Khed (Rajgurunagar)',	label:'Khed (Rajgurunagar)'},
    { value: 'Khuldabad',	label:'Khuldabad'},
    { value: 'Kinwat',	label:'Kinwat'},
    { value: 'Kopargaon',	label:'Kopargaon'},
    { value: 'Korchi',	label:'Korchi'},
    { value: 'Koregaon',	label:'Koregaon'},
    { value: 'Korpana',	label:'Korpana'},
    { value: 'Kudal',	label:'Kudal'},
    { value: 'Kuhi',	label:'Kuhi'},
    { value: 'Kurkheda',	label:'Kurkheda'},
    { value: 'Kurla',	label:'Kurla'},
    { value: 'Lakhandur',	label:'Lakhandur'},
    { value: 'Lakhni',	label:'Lakhni'},
    { value: 'Lanja',	label:'Lanja'},
    { value: 'Latur',	label:'Latur'},
    { value: 'Loha',	label:'Loha'},
    { value: 'Lohara',	label:'Lohara'},
    { value: 'Lonar',	label:'Lonar'},
    { value: 'Maan (Dahiwadi)',	label:'Maan (Dahiwadi)'},
    { value: 'Madha',	label:'Madha'},
    { value: 'Mahabaleshwar',	label:'Mahabaleshwar'},
    { value: 'Mahad',	label:'Mahad'},
    { value: 'Mahagaon',	label:'Mahagaon'},
    { value: 'Mahur',	label:'Mahur'},
    { value: 'Majalgaon',	label:'Majalgaon'},
    { value: 'Malegaon',	label:'Malegaon'},
    { value: 'Malegaon',	label:'Malegaon'},
    { value: 'Malkapur',	label:'Malkapur'},
    { value: 'Malshiras',	label:'Malshiras'},
    { value: 'Malwan',	label:'Malwan'},
    { value: 'Mandangad',	label:'Mandangad'},
    { value: 'Mangalvedhe',	label:'Mangalvedhe'},
    { value: 'Mangaon',	label:'Mangaon'},
    { value: 'Mangrulpir',	label:'Mangrulpir'},
    { value: 'Manora',	label:'Manora'},
    { value: 'Mantha',	label:'Mantha'},
    { value: 'Manwath',	label:'Manwath'},
    { value: 'Maregaon',	label:'Maregaon'},
    { value: 'Maval (Vadgaon)',	label:'Maval (Vadgaon)'},
    { value: 'Mehkar',	label:'Mehkar'},
    { value: 'Mhasla',	label:'Mhasla'},
    { value: 'Miraj',	label:'Miraj'},
    { value: 'Mohadi',	label:'Mohadi'},
    { value: 'Mohol',	label:'Mohol'},
    { value: 'Mokhada',	label:'Mokhada'},
    { value: 'Morshi',	label:'Morshi'},
    { value: 'Motala',	label:'Motala'},
    { value: 'Mouda',	label:'Mouda'},
    { value: 'Mudkhed',	label:'Mudkhed'},
    { value: 'Mukhed',	label:'Mukhed'},
    { value: 'Muktainagar (Edalabad)',	label:'Muktainagar (Edalabad)'},
    { value: 'Mul',	label:'Mul'},
    { value: 'Mulchera',	label:'Mulchera'},
    { value: 'Mulshi (Paud)',	label:'Mulshi (Paud)'},
    { value: 'Murbad',	label:'Murbad'},
    { value: 'Murtajapur',	label:'Murtajapur'},
    { value: 'Murud',	label:'Murud'},
    { value: 'Nagar',	label:'Nagar'},
    { value: 'Nagbhid',	label:'Nagbhid'},
    { value: 'Nagpur (Rural)',	label:'Nagpur (Rural)'},
    { value: 'Nagpur (Urban)',	label:'Nagpur (Urban)'},
    { value: 'Naigaon (Khairgaon)',	label:'Naigaon (Khairgaon)'},
    { value: 'Nanded',	label:'Nanded'},
    { value: 'Nandgaon',	label:'Nandgaon'},
    { value: 'Nandgaon Khandeshwar',	label:'Nandgaon Khandeshwar'},
    { value: 'Nandura',	label:'Nandura'},
    { value: 'Nandurbar',	label:'Nandurbar'},
    { value: 'Narkhed',	label:'Narkhed'},
    { value: 'Nashik',	label:'Nashik'},
    { value: 'Navapur',	label:'Navapur'},
    { value: 'Ner',	label:'Ner'},
    { value: 'Nevasa',	label:'Nevasa'},
    { value: 'Nilanga',	label:'Nilanga'},
    { value: 'Niphad',	label:'Niphad'},
    { value: 'Osmanabad',	label:'Osmanabad'},
    { value: 'Pachora',	label:'Pachora'},
    { value: 'Paithan',	label:'Paithan'},
    { value: 'Palam',	label:'Palam'},
    { value: 'Palghar',	label:'Palghar'},
    { value: 'Palus',	label:'Palus'},
    { value: 'Pandharpur',	label:'Pandharpur'},
    { value: 'Panhala',	label:'Panhala'},
    { value: 'Panvel',	label:'Panvel'},
    { value: 'Paranda',	label:'Paranda'},
    { value: 'Parbhani',	label:'Parbhani'},
    { value: 'Parli',	label:'Parli'},
    { value: 'Parner',	label:'Parner'},
    { value: 'Parola',	label:'Parola'},
    { value: 'Parseoni',	label:'Parseoni'},
    { value: 'Partur',	label:'Partur'},
    { value: 'Patan',	label:'Patan'},
    { value: 'Pathardi',	label:'Pathardi'},
    { value: 'Pathri',	label:'Pathri'},
    { value: 'Patoda',	label:'Patoda'},
    { value: 'Patur',	label:'Patur'},
    { value: 'Pauni',	label:'Pauni'},
    { value: 'Pen',	label:'Pen'},
    { value: 'Peth',	label:'Peth'},
    { value: 'Phaltan',	label:'Phaltan'},
    { value: 'Phulambri',	label:'Phulambri'},
    { value: 'Poladpur',	label:'Poladpur'},
    { value: 'Pombhurna',	label:'Pombhurna'},
    { value: 'Pune City',	label:'Pune City'},
    { value: 'Purandhar (Saswad)',	label:'Purandhar (Saswad)'},
    { value: 'Purna',	label:'Purna'},
    { value: 'Pusad',	label:'Pusad'},
    { value: 'Radhanagari',	label:'Radhanagari'},
    { value: 'Rahata',	label:'Rahata'},
    { value: 'Rahuri',	label:'Rahuri'},
    { value: 'Rajapur',	label:'Rajapur'},
    { value: 'Rajura',	label:'Rajura'},
    { value: 'Ralegaon',	label:'Ralegaon'},
    { value: 'Ramtek',	label:'Ramtek'},
    { value: 'Ratnagiri',	label:'Ratnagiri'},
    { value: 'Raver',	label:'Raver'},
    { value: 'Renapur',	label:'Renapur'},
    { value: 'Risod',	label:'Risod'},
    { value: 'Roha',	label:'Roha'},
    { value: 'Sadak Arjuni',	label:'Sadak Arjuni'},
    { value: 'Sailu',	label:'Sailu'},
    { value: 'Sakoli',	label:'Sakoli'},
    { value: 'Sakri',	label:'Sakri'},
    { value: 'Salekasa',	label:'Salekasa'},
    { value: 'Samudrapur',	label:'Samudrapur'},
    { value: 'Sangameshwar (Deorukh)',	label:'Sangameshwar (Deorukh)'},
    { value: 'Sangamner',	label:'Sangamner'},
    { value: 'Sangole',	label:'Sangole'},
    { value: 'Sangrampur',	label:'Sangrampur'},
    { value: 'Saoli',	label:'Saoli'},
    { value: 'Satara',	label:'Satara'},
    { value: 'Savner',	label:'Savner'},
    { value: 'Sawantwadi',	label:'Sawantwadi'},
    { value: 'Seloo',	label:'Seloo'},
    { value: 'Sengaon',	label:'Sengaon'},
    { value: 'Shahada',	label:'Shahada'},
    { value: 'Shahapur',	label:'Shahapur'},
    { value: 'Shahuwadi',	label:'Shahuwadi'},
    { value: 'Shegaon',	label:'Shegaon'},
    { value: 'Shevgaon',	label:'Shevgaon'},
    { value: 'Shirala',	label:'Shirala'},
    { value: 'Shirol',	label:'Shirol'},
    { value: 'Shirpur',	label:'Shirpur'},
    { value: 'Shirur',	label:'Shirur'},
    { value: 'Shirur Anantpal',	label:'Shirur Anantpal'},
    { value: 'Shirur-Kasar',	label:'Shirur-Kasar'},
    { value: 'Shrigonda',	label:'Shrigonda'},
    { value: 'Shrirampur',	label:'Shrirampur'},
    { value: 'Shrivardhan',	label:'Shrivardhan'},
    { value: 'Sillod',	label:'Sillod'},
    { value: 'Sindewahi',	label:'Sindewahi'},
    { value: 'Sindkhed Raja',	label:'Sindkhed Raja'},
    { value: 'Sindkheda',	label:'Sindkheda'},
    { value: 'Sinnar',	label:'Sinnar'},
    { value: 'Sironcha',	label:'Sironcha'},
    { value: 'Soegaon',	label:'Soegaon'},
    { value: 'Solapur North',	label:'Solapur North'},
    { value: 'Solapur South',	label:'Solapur South'},
    { value: 'Sonpeth',	label:'Sonpeth'},
    { value: 'Sudhagad (Pali)',	label:'Sudhagad (Pali)'},
    { value: 'Surgana',	label:'Surgana'},
    { value: 'Tala',	label:'Tala'},
    { value: 'Talasari',	label:'Talasari'},
    { value: 'Taloda',	label:'Taloda'},
    { value: 'Tasgaon',	label:'Tasgaon'},
    { value: 'Telhara',	label:'Telhara'},
    { value: 'Thane',	label:'Thane'},
    { value: 'Tiosa',	label:'Tiosa'},
    { value: 'Tirora',	label:'Tirora'},
    { value: 'Trimbakeshwar',	label:'Trimbakeshwar'},
    { value: 'Tuljapur',	label:'Tuljapur'},
    { value: 'Tumsar',	label:'Tumsar'},
    { value: 'Udgir',	label:'Udgir'},
    { value: 'Ulhasnagar',	label:'Ulhasnagar'},
    { value: 'Umarga',	label:'Umarga'},
    { value: 'Umarkhed',	label:'Umarkhed'},
    { value: 'Umred',	label:'Umred'},
    { value: 'Umri',	label:'Umri'},
    { value: 'Uran',	label:'Uran'},
    { value: 'Vada',	label:'Vada'},
    { value: 'Vaibhavwadi',	label:'Vaibhavwadi'},
    { value: 'Vaijapur',	label:'Vaijapur'},
    { value: 'Vasai',	label:'Vasai'},
    { value: 'Velhe',	label:'Velhe'},
    { value: 'Vengurla',	label:'Vengurla'},
    { value: 'Vikramgad',	label:'Vikramgad'},
    { value: 'Wadwani',	label:'Wadwani'},
    { value: 'Wai',	label:'Wai'},
    { value: 'Walwa (Islampur)',	label:'Walwa (Islampur)'},
    { value: 'Wani',	label:'Wani'},
    { value: 'Wardha',	label:'Wardha'},
    { value: 'Warora',	label:'Warora'},
    { value: 'Warud',	label:'Warud'},
    { value: 'Washi',	label:'Washi'},
    { value: 'Washim',	label:'Washim'},
    { value: 'Yavatmal',	label:'Yavatmal'},
    { value: 'Yawal',	label:'Yawal'},
    { value: 'Yeola',	label:'Yeola'},
    { value: 'Zari Jamani',	label:'Zari Jamani'},
  ];
class Dropdown extends Component {
    state = {
        selectedOption: null,
      };
      handleChange = selectedOption => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
      };
      render() {
        const { selectedOption } = this.state;
    
        return (
          <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
          />
        );
      }
}

export default Dropdown;