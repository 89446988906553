import "./App.css";
import React, { Component } from "react";
import Login from "./Login";
import Signup from "./Signup";
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import Home from "./Home";
import LoginHandler from "./LoginHandler";
import Dashboard from "./Dashboard";
import Attendance from "./Attendance";
import PreTraining from "./PreTraining";
import PostTraining from "./PostTraining";
import Feedback from "./Feedback";
import Certificate from "./Certificate";
import TrainingDropdown from "./TrainingDropdown";
import Admin from "./Admin";
import NewTraining from "./NewTraining";
import ExistingTrainings from "./ExistingTrainings";
import UserNewTraining from "./UserNewTraining";

export default class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Route exact path="/">
            <Redirect exact to="/SignUp" />
          </Route>
          <Route path="/Home" component={Home} />
          <Route path="/Login" component={LoginHandler} />
          <Route path="/TrainingDropdown" component={TrainingDropdown} />
          <Route path="/SignUp" component={Signup} />
          <Route path="/Dashboard" component={Dashboard} />
          <Route path="/Attendance" component={Attendance} />
          <Route path="/PreTraining" component={PreTraining} />
          <Route path="/PostTraining" component={PostTraining} />
          <Route path="/Feedback" component={Feedback} />
          <Route path="/Admin" component={Admin} />
          <Route path="/NewTraining" component={NewTraining} />
          <Route path="/ExistingTrainings" component={ExistingTrainings} />
          <Route path="/UserNewTraining" component={UserNewTraining} />
          {/* <Route path="/Certificate" component={Certificate}/> */}
        </Router>
      </div>
    );
  }
}
