import React, { useEffect, useState } from "react";
import {Button} from 'react-bootstrap'
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import heading from './assets/meetra_long.png'
import Footer from './Footer';
import fire from "./firebase/config";
import 'firebase/firestore';

export default function Feedback(props) {
    const history = useHistory();
    const location = useLocation();
    var trainingProg = location.state.trainingProg;
    let DIV;
    DIV = <div dangerouslySetInnerHTML={{ __html: trainingProg.feedbackLink}} />
    return (
        <div className="main-container">
            <center><img src={heading} width="70%"></img></center>
            <hr/>
            <div className="head">
            <h1>Feedback Form</h1>
            <Button className="btn1-signout" type="submit" onClick={() => history.push({pathname:'/Dashboard'})}>Home</Button></div>
            <hr/>
            
            <center>                                         
                {/* <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdgavV10-30LZ3nND4raTv2hpkq5jDTf_nH038GF22M8jLiPA/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} /> */}
                {/* <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScEcoz25e3U0wqUezfKdsxu49SWtbdlir2ZvgV9mYoQAqcR3A/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} /> */}
                {/* <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeYGLmDsdqqshhji3J4mEuI_42dRiX3bQMinhR5_tsBqxIjMQ/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} /> */}
                {/* <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdM_FEB2JNcIEAnb8BXubOXSNlPW3COVZcFX_FZfNwUrTC0Hg/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} /> */}
                {/* <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfj28gKiMAaZ8D8W5r_p2h8OJaGgIGwb13Ffu_K4W1gA4Acow/viewform?embedded=true" width="100%" height="410" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>'}} /> */}
                {DIV}
            </center>
            <Footer/>
        </div>
    )
}
