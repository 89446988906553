import firebase from "firebase";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyArjHWXtfGf-m1NmMIHev8mm1sUGWSxA_4",
//   authDomain: "meetra-training.firebaseapp.com",
//   projectId: "meetra-training",
//   storageBucket: "meetra-training.appspot.com",
//   messagingSenderId: "134357042075",
//   appId: "1:134357042075:web:a9fac05944a87de625173e"
// };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBF9uQxDm1MzWW_7W-ZLBvD1dhExHOHkZ4",
//   authDomain: "meetra-hydraulicstraining.firebaseapp.com",
//   projectId: "meetra-hydraulicstraining",
//   storageBucket: "meetra-hydraulicstraining.appspot.com",
//   messagingSenderId: "353744044420",
//   appId: "1:353744044420:web:1a33f9351a333c4817ddd1"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBG24xAm0DT2_sBQDDfEmFi0CXDvtinhWs",
//   authDomain: "meetra-nashik.firebaseapp.com",
//   projectId: "meetra-nashik",
//   storageBucket: "meetra-nashik.appspot.com",
//   messagingSenderId: "838998107105",
//   appId: "1:838998107105:web:cf9372bec5bae8f0dfe8a0",
//   measurementId: "G-QM014PY4L4"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDdkzp9rE_pwrV9u1MzTaUbk7pjtx-kBkk",
//   authDomain: "meetra-hydraulics2.firebaseapp.com",
//   projectId: "meetra-hydraulics2",
//   storageBucket: "meetra-hydraulics2.appspot.com",
//   messagingSenderId: "402426680504",
//   appId: "1:402426680504:web:011e440a464ccadc02d04d"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyC2eo2NtIRM-a-f76fYpnWbLXJO0M6ILZ4",
//   authDomain: "meetra3-f1dba.firebaseapp.com",
//   projectId: "meetra3-f1dba",
//   storageBucket: "meetra3-f1dba.appspot.com",
//   messagingSenderId: "432344810442",
//   appId: "1:432344810442:web:bd4105012baadfe4714533"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBrBCNqSwk66W29XYrntq0Y1FPglA8HJTk",
//   authDomain: "meetra-augendhydra.firebaseapp.com",
//   projectId: "meetra-augendhydra",
//   storageBucket: "meetra-augendhydra.appspot.com",
//   messagingSenderId: "376463337159",
//   appId: "1:376463337159:web:604450e27c11254576c0e2"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDcIp8luwF3sXu-0vr_lMbvuB4m7m2CxYk",
//   authDomain: "meetraaugend.firebaseapp.com",
//   projectId: "meetraaugend",
//   storageBucket: "meetraaugend.appspot.com",
//   messagingSenderId: "234544913854",
//   appId: "1:234544913854:web:e50adbb00342e6e3a92def"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDiCvki4H8HaQyIBO5_9dTyxgat-nLSu74",
//   authDomain: "meetra03092021.firebaseapp.com",
//   projectId: "meetra03092021",
//   storageBucket: "meetra03092021.appspot.com",
//   messagingSenderId: "52247547919",
//   appId: "1:52247547919:web:d92bf41b6f02db018f937a"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDMx7Yo97nO6anPpj5Mb6c8f-pngyLOWrc",
//   authDomain: "meetra07092021.firebaseapp.com",
//   projectId: "meetra07092021",
//   storageBucket: "meetra07092021.appspot.com",
//   messagingSenderId: "651938119776",
//   appId: "1:651938119776:web:721f862cec56cb9736c305"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDkLTdJtwwhP4lYmk5yAjB_Pus4F3-YyQs",
//   authDomain: "meetra08092021.firebaseapp.com",
//   projectId: "meetra08092021",
//   storageBucket: "meetra08092021.appspot.com",
//   messagingSenderId: "861883551092",
//   appId: "1:861883551092:web:6acaedcf2b9cd444f33021"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyB0k843wq8U3wuF_zxIDC_thCslnDAAh6o",
//   authDomain: "meetra-njjm.firebaseapp.com",
//   projectId: "meetra-njjm",
//   storageBucket: "meetra-njjm.appspot.com",
//   messagingSenderId: "692024854432",
//   appId: "1:692024854432:web:39ec943389645a718b46c5",
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDpoaFKBmxw_uPJspFp4e5EB2ONiGCOCgI",
//   authDomain: "meetra-new.firebaseapp.com",
//   projectId: "meetra-new",
//   storageBucket: "meetra-new.appspot.com",
//   messagingSenderId: "554234872503",
//   appId: "1:554234872503:web:8c3d2c217cb497fdf00332",
// };

const firebaseConfig = {
  apiKey: "AIzaSyB4__QsB48vEV-VDsGpAnvwydrMRYG4Sdc",
  authDomain: "meetra2023-ecc97.firebaseapp.com",
  projectId: "meetra2023-ecc97",
  storageBucket: "meetra2023-ecc97.appspot.com",
  messagingSenderId: "1045210389318",
  appId: "1:1045210389318:web:815e39487de67ba6792347",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDso44gYzKp9NgtKUMhOjYVjPfv60Id-Nc",
//   authDomain: "meetra2024-87a95.firebaseapp.com",
//   projectId: "meetra2024-87a95",
//   storageBucket: "meetra2024-87a95.appspot.com",
//   messagingSenderId: "808111913873",
//   appId: "1:808111913873:web:b11c8d2a988806eea43830",
// };

// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);

export default fire;
