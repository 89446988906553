import React, {useEffect, useState} from 'react'
import Dashboard from './Dashboard';
import fire from "./firebase/config";
import { useHistory } from 'react-router';

export default function Home() {
    const user = fire.auth().currentUser;
  const [info , setInfo] = useState([]);
  const [docID, setDocID] = useState([]);
  
    const [trainingListElement, setTrainingListElement]= useState('');
    const history = useHistory();
    let userEmail = user
    var userDocID;
    const db = fire.firestore();
  useEffect(() => {
    //Fetchdata();
  },[]);
  const Fetchdata = ()=>{
//     const ref = fire.firestore().collection('users')
//    ref.where("email", '==', userEmail)
//   .get()
//   .then((querySnapshot) => {
//     querySnapshot.forEach((doc) => {
//       console.log(doc.id, "=>", doc.data());
//     });
//   });
    db.collection("users").get().then((querySnapshot) => {
         
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach((doc) => {
            setDocID(`${doc.id}`)
            var data = doc.data();
            setInfo(arr => [...arr , data]);
            // console.log(data.name);
        });
    })
    // db.collection('users').doc(docID).get()
}
    
        var userName;
        var trainingProg;
        info.map((data)=>{
            if(data.email === user.email){
                userName = data.name;
                userDocID = docID;
                // console.log("DocID is: "+docID);
                trainingProg = data.trainingProg;
            }
        }
        )
        console.log(userDocID);
    return (
        
        <div>
            <br/><br/>
            <center>
                <h1>No current training session in process. Portal will be live 3 hours before next training session.</h1>
                {/* <h2>The portal will be live from Monday, 09/08/2021, at 08:00 a.m.</h2> */}
            </center>
            {/* Select training name:
            <select onChange={e => {setTrainingListElement(e.target.value); }} >
                <option selected disabled>Program</option>
                <option value="Orientation on JJM with DPR preparation (29/07/2021)">Orientation on JJM with DPR preparation (29/07/2021) - Closed</option>
                <option value="Orientation on JJM with DPR preparation (30/07/2021)">Orientation on JJM with DPR preparation (30/07/2021)</option>
            </select> */}
            {/* <button onClick={history.push('/Dashboard', trainingListElement, {userName})}>Dashboard</button> */}
        </div>
    )
}
